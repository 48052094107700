import { Injectable, computed, signal } from '@angular/core';
import { graphQLClient } from '@app/frontend-core';
import { AICharacterInformation, AppPeopleUser, ViewUserDocument, informationOfCharacters } from '@app/generated';
import { injectQuery } from '@tanstack/angular-query-experimental';

@Injectable({ providedIn: 'root' })
export class CharacterGear {
    currentCharacterId = signal<string | null>(null);
    characterUser = signal<AppPeopleUser | null>(null);
    informationOfCharacters = informationOfCharacters;

    userSuggestions = computed(() => informationOfCharacters
        .find(c => c.characterId === this.currentCharacterId())?.userSuggestions);

    setDefaultCharacter(characterId?: string | null) {
        if (!!!characterId?.length) {
            characterId = informationOfCharacters[0].characterId;
        }
        this.currentCharacterId.set(characterId);
        return characterId;
    }

    characterUserQuery = injectQuery(() => ({
        queryKey: ['characterUser', this.currentCharacterId()],
        queryFn: async ({ queryKey }) => {
            const fetchCharacterUser = async (characterId: string | null) => {
                if (!characterId) throw new Error('no character id');
                const { appPeopleUsers } = await graphQLClient.request(ViewUserDocument, { userId: characterId });
                return appPeopleUsers?.nodes.length ? appPeopleUsers?.nodes[0] : null;
            };
            const characterUser = await fetchCharacterUser(queryKey[1]);
            this.characterUser.set(characterUser as (AppPeopleUser | null));

            if (characterUser?.userId) {
                localStorage.setItem('characterId', characterUser.userId);
            } else {
                console.warn('this char not havae profile', characterUser);
                localStorage.removeItem('characterId');
            }
            return characterUser;
        },
    }));

}