import { computed, inject, Injectable, signal } from '@angular/core';
import { checkAccessTokenExpired, SnackbarService, UniversalService } from '@app/frontend-core';
import { DialogService } from '@ngneat/dialog';
import { PeopleHelpGear } from './people-help.gear.js';
import {
  LoginDocument,
  LoginMutationVariables,
  LogoutDocument,
  MyProfileDocument,
  RegisterDocument,
  RegisterMutationVariables,
  VerifyEmailDocument,
  VerifyEmailMutationVariables,
  LastActiveDocument,
  MyProfileQuery,
  ViewUserDocument
} from '@app/generated';
import { injectMutation, injectQuery, provideQueryClient, injectQueryClient, QueryClient } from '@tanstack/angular-query-experimental';
import { graphQLClient, PlausibleService } from '@app/frontend-core';

@Injectable({ providedIn: 'root' })
export class PeopleGear {
  universalService = inject(UniversalService);
  plausibleService = inject(PlausibleService);
  snackbarService = inject(SnackbarService);
  dialogService = inject(DialogService);
  peopleHelpGear = inject(PeopleHelpGear);
  queryClient: QueryClient = injectQueryClient();
  lastActive?: number;
  // myProfile = qQuery(MyProfileDocument);
 

  viewUserQuery = (userId: string) => injectQuery(() => ({
    queryKey: ['viewUser', userId],
    queryFn: async ({ queryKey }) => {
    const { appPeopleUsers } = await graphQLClient.request(
        ViewUserDocument, { userId: queryKey[1] }
      );
      return appPeopleUsers;
    }
  }));

  // Initialize myProfile with a loading state
  myProfile = signal<MyProfileQuery['appPeopleMyProfile'] | null | undefined>(undefined);
  isLoggedIn = computed(() =>this.myProfile()?.userId);

  // Inject the query
  myProfileQuery = injectQuery(() => ({
    queryKey: ['appPeopleMyProfile'],
    queryFn: async ({ queryKey }) => {
      this.myProfile.set(undefined);

      const accessTokenStillValid = await checkAccessTokenExpired();

      switch (accessTokenStillValid) {
        case true:
          try {
            let cachedMyProfile = this.queryClient.getQueryData(['appPeopleMyProfile']) as MyProfileQuery;
            let response = cachedMyProfile;
            console.log('accessTokenStillValid, what is cache profile?', response);

            if (response?.appPeopleMyProfile) {
              console.log('Using cached appPeopleMyProfile data');
              this.myProfile.set(response.appPeopleMyProfile);
              return response.appPeopleMyProfile;
            } else {
              console.log('Fetching new appPeopleMyProfile data');
            }
            response = await graphQLClient.request(MyProfileDocument);

            if (!this.lastActive || Date.now() - this.lastActive > 60 * 1000) {
              this.lastActiveMutation.mutate();
              this.lastActive = Date.now();
            }

            if (!response.appPeopleMyProfile) {
              console.log('No profile data, logging out');
              this.peopleHelpGear.handleLogout(false);
              this.myProfile.set(null);
              return null;
            }
            console.log('saving appPeopleMyProfile data to myProfile variable');
            this.myProfile.set(response.appPeopleMyProfile);
            return response.appPeopleMyProfile;
          } catch (error) {
            console.error('Error fetching profile:', error);
            this.snackbarService.showError(error.message);
            this.myProfile.set(null);
            throw error;
          }
        case false:
          console.log('Access token expired');
          this.snackbarService.showWarning("Access token expired");
          this.myProfile.set(null);
          return null;
        default:
          console.log('Unexpected token state, logging out');
          this.peopleHelpGear.handleLogout(false);
          this.myProfile.set(null);
          return null;
      }
    },
    refetchOnWindowFocus: true,
    enabled: this.universalService.isBrowser(),
    networkMode: 'offlineFirst',
  }));



  lastActiveMutation = injectMutation(() => ({
    mutationFn: () => graphQLClient.request(LastActiveDocument),
    onSuccess: (data, variables, context) => {
    }
  }))

  registerEmailMutation = injectMutation(() => ({
    mutationFn: (v: RegisterMutationVariables) => graphQLClient.request(RegisterDocument, v),
    onError: (error, variables, context) => {
      this.peopleHelpGear.handleError(error);
    },
    onSuccess: (data, variables, context) => {
      this.snackbarService.showSuccess(`Đăng ký thành công, vui lòng kích hoạt email ${variables.email}`);
      this.plausibleService.trackEvent('Signup', { props: { provider: 'email', status: 'pending' } });
    },
  }));

  loginEmailMutation = injectMutation(() => ({
    mutationFn: (v: LoginMutationVariables) => graphQLClient.request(LoginDocument, v),
    onError: (error, variables, context) => {
      this.peopleHelpGear.handleError(error);
    },
    onSuccess: (data, variables, context) => {
      this.peopleHelpGear.handleLogin(data.login);
      console.log('login by email data:', data.login);
      this.myProfileQuery.refetch();
      
    },
  }));

  verifyEmailMutation = injectMutation(() => ({
    mutationFn: (v: VerifyEmailMutationVariables) => graphQLClient.request(VerifyEmailDocument, v),
    onError: (error, variables, context) => {
      this.peopleHelpGear.handleError(error);
    },
    onSuccess: (data, variables, context) => {
      if (data.appPeopleVerifyEmail?.result) {
        this.snackbarService.showSuccess('Xác minh thành công, hãy đăng nhập lại nhé!', { duration: 3000 });
        this.plausibleService.trackEvent('Verify', { props: { provider: 'email', status: 'verified' } });
      } else {
        this.snackbarService.showError();
      }
    },
  }))

  logoutMutation = injectMutation(() => ({
    mutationFn: () => graphQLClient.request(LogoutDocument),
    onSuccess: (data, variables, context) => {
      this.myProfile.set(null);
      this.peopleHelpGear.handleLogout();
    }
  }))

}
