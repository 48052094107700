<ul class=" flex flex-col items-start h-full w-full">
    @for (item of collapseTabs; track $index) {

    <li tabindex="0" class="transition-none animate-none relative collapse rounded-none my-0"
        [ngClass]="[(item.opened ? 'collapse-open min-h-44 flex-1' : 'collapse-close h-fit'), ($first ? 'max-h-44' : '')]">

        <input class="min-h-fit" title="chọn hội thoại" type="checkbox" (change)="item.opened = !item.opened" />
        <div
            class="collapse-title py-2 px-1 min-h-10 self-center font-bold text-base-content flex justify-between items-center text-xs md:text-sm lg:text-base border-base-200 border-b-2">
            {{ item.name }}
            <lucide-icon class="ml-1 inline-flex"
                [name]="!item.opened ? icons.ChevronRight : icons.ChevronDown"></lucide-icon>

        </div>
        @if(item.action) {
        <a class="btn btn-xs btn-outline cursor-pointer hover:bg-primary hover:text-primary-content absolute top-2 right-0 z-[1] bg-base-200"
            (click)="action({type: item.action.actionType})">
            {{ item.action.actionName }}
            <lucide-icon class="ml-1" [name]="item.action.actionIcon"></lucide-icon>
        </a>
        }
        <!-- NOTE: STEP 2:realative + h-full + overflow-y-scroll-->
        <div class="transition-none animate-none collapse-content relative flex flex-col overflow-y-scroll px-0 py-0">
            @switch (item.id) {
            @case ('recent-characters') {
            <ng-container [ngTemplateOutlet]="charactersTemplate"
                [ngTemplateOutletContext]="{  }"></ng-container>
            }
            @case ('conversations') {
            <ng-container [ngTemplateOutlet]="conversationsTemplate"
                [ngTemplateOutletContext]="{  }"></ng-container>
            }
            }
        </div>
    </li>
    }

    @if (subscriptionGear.currentSubscription()?.subscriptionType !== 'paid') {
    <ng-container [ngTemplateOutlet]="upgradeTemplate"></ng-container>
    }

</ul>

<ng-template #charactersTemplate>
    @for (char of recentCharacters(); track $index) {
    <!-- <li class="w-full px-4 flex flex-row" -->
    <li class="w-full flex flex-row justify-between items-center rounded-lg px-2 cursor-pointer hover:border hover:border-accent"
        [ngClass]="char?.characterId === characterGear.currentCharacterId() ? 'bg-base-300' : ''">
        <a (click)="action({type: 'select-character', data: char})"
            class="!h-full py-2  text-xs md:text-sm lg:text-base truncate  p-0 w-full !flex justify-start items-center">
            {{ char.characterDisplayName || 'không tên?' }}
        </a>
    </li>
    }
</ng-template>

<ng-template #conversationsTemplate let-limit="limit">
    @for (conversation of conversations.data()?.slice(0, limit); track $index) {
    <!-- <li class="w-full px-4 flex flex-row" -->
    <li class="w-full flex flex-row justify-between items-center rounded-lg px-2 cursor-pointer hover:border hover:border-accent"
        [ngClass]="conversation?.conversationId === messageGear.activeConversationId() ? 'bg-base-300' : ''">
        <a (click)="action({type: 'select-conversation', data: conversation})"
            class="!h-full py-2  text-xs md:text-sm lg:text-base truncate  p-0 w-full !flex justify-start items-center">
            {{ conversation?.conversationTitle || 'Trò chuyện không tiêu đề' }}
        </a>
        <div class="dropdown dropdown-end w-8">
            <div tabindex="0" role="button" class="btn btn-xs" (click)="toggleElementOnClick($event)">
                <lucide-icon class="w-full block text-center" [name]="icons.EllipsisVertical"></lucide-icon>
            </div>
            <ul tabindex="0" class="menu menu-sm dropdown-content bg-base-100 rounded-box p-2 shadow z-[1]">
                <li (click)="action({type: 'delete-conversation', data: conversation})"><a>xóa</a></li>
            </ul>
        </div>
    </li>
    }
</ng-template>

<ng-template #upgradeTemplate>
    <div (click)="action({type: 'upgrade'})"
        class="absolute left-0 bottom-0 flex items-center gap-2 w-full cursor-pointer bg-base-300 hover:bg-base-200">
        <span class="flex h-7 w-7 items-center justify-center rounded-full border border-token-border-light">✨</span>
        <div class="flex flex-col">
            <span class="font-semibold">Nâng cấp</span>
            <span class="line-clamp-1 text-xs">Nhận mô hình cao cấp và nhiều tính năng khác</span>
        </div>
    </div>
</ng-template>