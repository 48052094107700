import { afterNextRender, afterRender, ChangeDetectorRef, computed, inject, signal } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { filter, map, startWith, tap } from 'rxjs';
import { CharacterGear, MessageGear, PeopleGear, SubscriptionGear } from '@app/frontend-state';
import { DialogService } from '@ngneat/dialog';
import { ActionEvent, clickInsideBlur, checkIsMobile, toggleElementOnClick } from '@app/frontend-core';
import { ChatterNavbarState } from '../chatter-navbar.state.js';
import { DialogsPeople } from 'frontend-ui/src/people/index.js';
import { ChangeDetectionStrategy, Component, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { CharacterAvatarComponent } from 'frontend-ui/src/chatter-dashboard/components/character-avatar.component.js';
import { DataMenuInterface } from 'configs/src/navigation/routing-config.js';
import { User, LucideAngularModule, ChevronDown, Menu,  Home , Store } from 'lucide-angular';
import { toSignal } from '@angular/core/rxjs-interop';
import { ChatLayout } from '../chat/chat.layout.js';
import { SidebarOrgan } from '../sidebar/sidebar.organ.js';
import { NavrightOrgan } from '../navright/navright.organ.js';
import { EditorLayout } from '../editor/editor.layout.js';

@Component({
  selector: 'layout-main',
  standalone: true,
  imports: [
    CommonModule,
    SidebarOrgan,
    RouterOutlet,
    CharacterAvatarComponent,
    RouterLink,
    LucideAngularModule,
    ChatLayout,
    EditorLayout
  ],
  templateUrl: 'main.layout.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1 1 0%;
        justify-content: center;
        flex-direction: column;
      }

      
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayout {
  cd = inject(ChangeDetectorRef)
  document = inject(DOCUMENT);
  toggleElementOnClick = toggleElementOnClick;
  clickInsideBlur = clickInsideBlur;
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);
  location = inject(Location);
  peopleGear = inject(PeopleGear);
  dialogsPeople = inject(DialogsPeople);
  dialogService = inject(DialogService);
  subscriptionGear = inject(SubscriptionGear);
  messageGear = inject(MessageGear);
  characterGear = inject(CharacterGear);
  chatterNavbarState = inject(ChatterNavbarState);
  myProfileQuery = this.peopleGear.myProfileQuery;
  icons = {
    User, ChevronDown, Menu
  }
  ui = {
    titlePage: '',
    
  };
  activeTutorialTab = signal(0);

  currentURL = toSignal<string>(this.router.events
    .pipe(
      startWith(this.router.url),
      filter((e) => e instanceof NavigationEnd),
      // tap(e => console.log(e, this.router, this.activatedRoute.snapshot)),
      map((e: any) => e.url)
  ), { initialValue: this.router.url as any });
  

  sidebarNavigations = [
    { title: 'Trang chủ', url: '/' },
    { title: 'Trò chuyện', url: '/c' },
    { title: 'Blog', url: '/blog' },
  ];

  tabListItems = [
    { title: 'Trò chuyện', id: 'home', url: '/c' },
    { title: 'Soạn thảo', id: 'home', url: '/d' },

  ];

  // bottomItems: Record<string, { title: string, id: string, url: string, icon: LucideIconData }[]> = {
  bottomItems = {
    ['/c' as string]: [
      { title: 'Nhà', id: 'home', url: '/c/home', icon: Home, },
      { title: 'Cửa hàng', id: 'store', url: '/c/character-store', icon: Store,},
    ]
  };

  getBottomItems = computed(() => Object.keys(this.bottomItems)
    .map(key => this.currentURL()?.startsWith(key) ? this.bottomItems[key] : [])
    .flat()
  );

  constructor() {
    // console.log('did I init?')
    afterNextRender(() => {
      this.devtoolDebug();
      this.chatterNavbarState.toggleLeftBarsMenu(checkIsMobile() === true ? false : true);
      const profile = this.peopleGear.myProfile();
      console.log("profile here", profile);
    })
  }

  selectTab(tab: { title: string, id: string, url: string }) {
    this.activeTutorialTab.set(this.tabListItems.findIndex(item => item.id === tab.id));
    this.router.navigate([tab.url]);
  }

  setNavbarData(dataMenu?: DataMenuInterface) {
    // const dataMenu = this.chatterNavbarState.dataNavigationMenus().find(item => item.linkTo === url);
    // console.log('setNavbarData', this.router, this.router.url, dataMenu, this.chatterNavbarState.dataNavigationMenus());
    let resultDataMenu = dataMenu;
    const shouldNavigateTab = !!resultDataMenu;
    if (shouldNavigateTab && resultDataMenu) {
      console.log("WARNING: navigate")
      this.router.navigate([resultDataMenu.linkTo], { replaceUrl: true, queryParamsHandling: 'merge' });
    } else {
      resultDataMenu = this.chatterNavbarState.dataNavigationMenus().find(item => this.router.url.startsWith(item.linkTo ?? ''));
    }

    if (resultDataMenu) {
      console.log('dataMenu exist', dataMenu);
      this.chatterNavbarState.toggleNavigationMenu(resultDataMenu.routerId);
    }

  }

  devtoolDebug() {
    const search = this.document.location.search;
    const username = this.peopleGear.myProfile()?.username;
    // console.log('your username', username)
    if (search.includes('debug')) {
      // if (username === 'hiepxanh') {
      import('eruda').then(eruda => {
        eruda.default.init();
        console.log('url', search, this.document.location, this.peopleGear.myProfile());
      });

    }
  }

  action(event: ActionEvent) {
    console.log('action', event);
    switch (event.type) {
      case 'bottom-navbar-select':
        this.router.navigate([event.data.url]);
        break;
      case 'open-menu-navright':
        this.dialogService.open(NavrightOrgan)
        break;
      case 'select-tab':
        this.selectTab(event.data);
        break;
      case 'login':
        this.dialogsPeople.openDialogLogin();
        break;
      case 'register':
        this.dialogsPeople.openDialogRegister();
        break;
      case 'back':
        this.location.back();
        break;

      case 'go-settings':
        this.router.navigate(['/settings']);
        break;
      case 'toggle-tab':
        // console.log('toggle-tab', event.data);
        this.chatterNavbarState.editorMenuLeft.update((dataMenus) => {
          return dataMenus.map((dataMenu) => {
            if (dataMenu.routerId === event.data.routerId) {
              dataMenu.isActive = !dataMenu.isActive;
            } else {
              dataMenu.isActive = false;
            }
            return dataMenu;
          });
        });
        break;
      case 'toggle-modifer':
        // console.log('toggle-menu-modifer', event.data);
        this.chatterNavbarState.dataModiferMenus.update((dataMenus) => {
          return dataMenus.map((dataMenu) => {
            if (dataMenu.routerId === event.data.routerId) {
              dataMenu.isActive = !dataMenu.isActive;
            }
            return dataMenu;
          });
        });
        break;
      case 'toggle-navigation':
        console.log('toggle-menu-navigation', event.data);
        // this.chatterNavbarState.toggleNavigationMenu(event.data.routerId);
        this.setNavbarData(event.data);
        break;

      default:
        console.log(event);
        break;
    }
  }
}
